import { axiosInstance } from "../Api";

export const getEditProfile = async (newProfileData) => {
  console.log("newProfileData", newProfileData);
  try {
    const editProfileResponse = await axiosInstance.post(
      "/edit-profile",
      newProfileData
    );
    console.log("editProfileResponse;;;;;;;", editProfileResponse);
    return editProfileResponse.data;
  } catch (err) {
    console.log("editprofile fetch failed:", err);
  }
};
