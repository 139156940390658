import React, { useEffect, useState } from "react";

import parse from "html-react-parser";

import { MoonLoader } from "react-spinners";

import OwlCarousel from "react-owl-carousel";
import { BiSolidCheckCircle } from "react-icons/bi";

import { Col, Container, Row } from "react-bootstrap";
import { getAboutApi, getAboutBrandApi } from "../../../Api/about/About";

import { BreadCurmbs } from "../../Breadcrumbs/Breadcrumbs";
import { getBrandApi } from "../../../Api/brandApi/BrandApi";

import { getReasonWhyApi } from "../../../Api/reasonWhy/WhyUsApi";
import { GetStarted } from "../../HomePage/getstarted/GetStarted";

import "./About.css";
import { useBrandContext } from "../../Brands/BrandContextApi";
import Loading from "../../loader/Loading";

export const AboutPage = () => {
  const { getUpdateBrandId } = useBrandContext();

  const [brand, setBrand] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(true);
  const [aboutFirst, setAboutFirst] = useState("");
  const [brandAbout, setBrandAbout] = useState("");

  const brandslider = {
    loop: true,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  useEffect(() => {
    const getAboutpage = async () => {
      try {
        const updateAboutApi = await getAboutApi();
        setAboutFirst(updateAboutApi.data[0]);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
        setLoading(false);
      }
    };
    getAboutpage();
  }, []);

  useEffect(() => {
    const getBrand = async () => {
      try {
        const updatedBrand = await getBrandApi();
        setBrand(updatedBrand.data);
      } catch (err) {
        console.log("error", err);
      }

      try {
        const updatedReasonWhy = await getReasonWhyApi();
        setReason(updatedReasonWhy.data[0]);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
        setLoading(false);
      }
    };
    getBrand();
  }, []);

  const handleClickBrand = (id) => {
    getUpdateBrandId(id);
  };

  useEffect(() => {
    const updateAboutBrand = async () => {
      try {
        const aboutUsData = await getAboutBrandApi();
        setBrandAbout(aboutUsData.data);
      } catch (err) {
        console.log("err", err);
      }
    };
    updateAboutBrand();
  }, []);

  return (
    <>
      <BreadCurmbs title="About Danfe" subtitle="Know more" />
      <section className="about-section innerabout">
        <Container>
          <>
            <div className="about-page-section">
              {loading || aboutFirst?.length === 0 ? (
                <div className="loader-brand">
                  <Loading />
                </div>
              ) : (
                <Row>
                  {aboutFirst?.blocks?.map((item, index) => (
                    <>
                      {index % 2 === 0 ? (
                        <>
                          <Col md={6} className="  left-wrap">
                            <div className="about-img">
                              <img src={item?.image} alt="about-image" />
                            </div>
                          </Col>

                          <Col md={6} className="pl-50 right-wrap">
                            <div className="about-content">
                              <div className="cl-header p-header">
                                <span className="subtitle ">
                                  {aboutFirst?.meta_title}
                                </span>
                                <h2 className="maintitle">{item.title}</h2>
                              </div>
                              <div className="content">
                                <p className="italic">
                                  Danfe Trading gives you a chance to quickly &
                                  easily find the phone you want and have it
                                  delivered to your home in no time.
                                </p>
                                <p>
                                  {console.log(item.content) ||
                                    parse(`${item.content || ""}`)}
                                </p>
                                {index === 0 && (
                                  <ul className="info">
                                    <li>
                                      <div className="img-logo">
                                        <img src="images/about/shield.png" />
                                      </div>
                                      <div className="text">
                                        <strong>200+</strong>
                                        <span>Products Sold</span>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="img-logo">
                                        <img src="images/about/world.png" />
                                      </div>
                                      <div className="text">
                                        <strong>800+</strong>
                                        <span>Vendors worldwide</span>
                                      </div>
                                    </li>
                                  </ul>
                                )}

                                {index === 1 && (
                                  <ul>
                                    <li className="mb-3">
                                      <BiSolidCheckCircle className="mr-10 icon" />
                                      Sponsoring and following individual farms
                                    </li>
                                    <li className="mb-3">
                                      <BiSolidCheckCircle className="mr-10 icon" />
                                      Sell your products via Danphe
                                    </li>
                                    <li className="mb-3">
                                      <BiSolidCheckCircle className="mr-10 icon" />
                                      Receive notifications when we publish
                                    </li>
                                    <li className="mb-3">
                                      <BiSolidCheckCircle className="mr-10 icon" />
                                      Available on Android & iOS
                                    </li>
                                  </ul>
                                )}
                              </div>
                            </div>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col md={6} className="pl-50 right-wrap">
                            <div className="about-content">
                              <div className="cl-header p-header">
                                <span className="subtitle">
                                  {aboutFirst?.meta_title}
                                </span>
                                <h2 className="maintitle">{item.title}</h2>
                              </div>
                              <div className="content">
                                <p
                                  className="italic"
                                  style={{ marginBottom: "24px" }}
                                >
                                  Danfe Trading gives you a chance to quickly &
                                  easily find the phone you want and have it
                                  delivered to your home in no time.
                                </p>
                                <p>
                                  {console.log(item.content) ||
                                    parse(`${item.content || ""}`)}
                                </p>
                                {index === 0 && (
                                  <ul className="info">
                                    <li>
                                      <div className="img-wrap">
                                        <img src="images/about/shield.png" />
                                      </div>
                                      <div className="text">
                                        <strong>200+</strong>
                                        <span>Products Sold</span>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="img-wrap">
                                        <img src="images/about/world.png" />
                                      </div>
                                      <div className="text">
                                        <strong>800+</strong>
                                        <span>Vendors worldwide</span>
                                      </div>
                                    </li>
                                  </ul>
                                )}

                                {index === 1 && (
                                  <ul className="about-points">
                                    <li className="mb-3">
                                      <BiSolidCheckCircle className="mr-10 icon-about" />
                                      Sponsoring and following individual farms
                                    </li>
                                    <li className="mb-3">
                                      <BiSolidCheckCircle className="mr-10 icon-about" />
                                      Sell your products via Danphe
                                    </li>
                                    <li className="mb-3">
                                      <BiSolidCheckCircle className="mr-10 icon-about" />
                                      Receive notifications when we publish
                                    </li>
                                    <li className="mb-3">
                                      <BiSolidCheckCircle className="mr-10 icon-about" />
                                      Available on Android & iOS
                                    </li>
                                  </ul>
                                )}
                              </div>
                            </div>
                          </Col>

                          <Col md={6} className="  left-wrap">
                            <div className="about-img">
                              <img src={item?.image} alt="about-image" />
                            </div>
                          </Col>
                        </>
                      )}
                      {index === Math.floor(aboutFirst?.blocks?.length / 4) && (
                        <>
                          <div className="brand-section section-padding">
                            <Container>
                              <Row className="row-banner-section">
                                <Col md={6}>
                                  <div className="cl-header">
                                    <span className="subtitle">
                                      Global Collaboration
                                    </span>
                                    <h2 className="maintitle cl-header-maintitle">
                                      {brandAbout[0]?.blocks[0]?.title}
                                    </h2>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="content">
                                    <p>
                                      {brandAbout[0]?.blocks[0]?.content &&
                                        parse(
                                          String(
                                            brandAbout[0]?.blocks[0]?.content
                                          )
                                        )}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>

                          <OwlCarousel
                            className="owl-theme owl-carousel top-brand-header brand-carousel banner-image-carousel"
                            {...brandslider}
                          >
                            {Array.isArray(brand) &&
                              brand?.map((data, index) => (
                                <div className="item" key={index}>
                                  <div
                                    className="brand-wrap"
                                    onClick={() => handleClickBrand(data.id)}
                                  >
                                    <div className="img-wrap">
                                      <img src={data.photo} alt="brand1" />
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </OwlCarousel>
                        </>
                      )}
                    </>
                  ))}
                </Row>
              )}
            </div>

            <div className="whyus-section section-padding">
              <div className="cl-header">
                <span className="subtitle">Why CHOOSE US</span>
                <h2 className="maintitle">Reasons To Choose Us</h2>
              </div>
              {loading || reason?.length === 0 ? (
                <div className="loader-brand">
                  <Loading />
                </div>
              ) : (
                <Row>
                  {reason?.blocks?.map((data, index) => (
                    <Col md={4} sm={6} className="p-0">
                      <div className="whyus-wrap">
                        <div className="icon-wrap">
                          <img
                            src={data.image}
                            alt=""
                            style={{
                              maxWidth: "17%",
                            }}
                          />
                          <span class="sn">
                            {index < 9 ? `0${index + 1}` : index + 1}
                          </span>
                        </div>
                        <div className="content">
                          <h2 className="title">{data?.title}</h2>
                          <p>
                            {console.log(data?.content) ||
                              parse(`${data?.content || ""}`)}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </>
        </Container>
      </section>
      <GetStarted style={{ margin: "96px" }} />
    </>
  );
};
