import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";

import { GlobalContext } from "../../../Context/GlobalContext";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { getEnquireSingleApi } from "../../../Api/enquireApi/EnquireSingle";

import "./popup.css";


const Popup = ({ onClick, title, popupData, setPopupData }) => {
  const navigate = useNavigate();
  const { profile } = useContext(GlobalContext);
  const [userData, setUserData] = useState({
    firstName: profile?.firstname || "",
    lastName: profile?.lastname || "",
    address: "",
    countryName: "",
    phoneNumber: "",
    emailId: profile?.email || "",
    id: "",
    quantityNumber: "",
  });

  const [manualData, setManualData] = useState({
    address: "",
    // lastName: "",
    countryName: "",
    phoneNumber: "",
    quantityNumber: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    countryName: "",
    address: "",
    quantityNumber: "",
  });
  console.log("validationErrors", validationErrors);
  const [showPopup, setShowPopup] = useState(true);
 

  const handleClosePopup = () => {
    setShowPopup(false);
    onClick();
  };

  const validateForm = () => {
    const errors = {};
   
    if (!userData?.firstName?.trim()) {
      errors.firstName = "First name is required";
    }

    if (!userData?.emailId?.trim()) {
      //trim () removes any leading or trailing spaces from a string
      errors.emailId = "Email is required";
    }

    if (!manualData?.phoneNumber?.trim()) {
      errors.phoneNumber = "Phonenumber is required";
    }

    if (!manualData?.countryName?.trim()) {
      errors.countryName = "Counteryname is required";
    }

    if (!manualData?.address?.trim()) {
      errors.address = "Address is required";
    }

    if (!manualData?.quantityNumber?.trim()) {
      errors.quantityNumber = "Quantitynumber is required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleGetEnquireSingle = async () => {
    try {
      if (validateForm()) {
        const combinedData = {
          ...userData,
          ...manualData,
          ...popupData,
        };

        const updateEnquireSingleApi = await getEnquireSingleApi(combinedData);
        console.log("updateEnquireSingleApi", updateEnquireSingleApi);
        navigate("/submitpage");
        setShowPopup(true);
      } else {
        console.log("Validation failed. Please check the form for errors.");
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const shouldShowProductId = false;
  return (
    <div className="popup-container">
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Enquire </Modal.Title>
          <RxCrossCircled className="icon-popup" onClick={handleClosePopup} />
        </Modal.Header>
        <Modal.Body
          className="scrollable-modal-body"
          style={{ paddingTop: "0" }}
        >
          <Form className="popup-row">
            <Row>
              <Col md={12} className="popup-col">
                <Form.Label>Product Name</Form.Label>
                <Form.Control type="text" name="productName" value={title} />
              </Col>

              <Col md={6} className="popup-col">
                <Form.Label>First Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="Enter Name"
                  value={userData.firstName}
                  onChange={(e) =>
                    setUserData({ ...userData, firstName: e.target.value })
                  }
                />
                {validationErrors.firstName && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.firstName}
                  </small>
                )}

                {userData.firstName !== profile?.firstname && (
                  <small className="text-danger" style={{ color: "red" }}>
                    Name does not match the registered name.
                  </small>
                )}
              </Col>

              <Col md={6} className="popup-col">
                <Form.Label>Last Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="LastName"
                  value={userData.lastName}
                  onChange={(e) =>
                    setManualData({
                      ...userData,
                      lastName: e.target.value,
                    })
                  }
                />
                {validationErrors.lastName && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.lastName}
                  </small>
                )}
              </Col>

              <Col md={6} className="popup-col">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  value={userData.emailId}
                  onChange={(e) =>
                    setUserData({ ...userData, emailId: e.target.value })
                  }
                />
                {validationErrors.emailId && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.emailId}
                  </small>
                )}

                {userData.emailId !== profile?.email && (
                  <small className="text-danger" style={{ color: "red" }}>
                    Email does not match the registered name.
                  </small>
                )}
              </Col>

              <Col md={6} className="popup-col">
                <Form.Label>Phone Number*</Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  inputMode="numeric"
                  placeholder="Enter Phone Number"
                  value={manualData.phoneNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const isValidPhoneNumber = /^[0-9()+\- ]*$/.test(
                      inputValue
                    );

                    if (isValidPhoneNumber) {
                      setManualData({
                        ...manualData,
                        phoneNumber: inputValue,
                      });
                    }
                  }}
                  // onChange={(e) =>
                  //   setManualData({
                  //     ...manualData,
                  //     phoneNumber: e.target.value,
                  //   })
                  // }
                />
                {validationErrors.phoneNumber && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.phoneNumber}
                  </small>
                )}
              </Col>

              <Col md={4} className="popup-col">
                <Form.Label>Country*</Form.Label>
                <Form.Control
                  type="text"
                  name="CountryName"
                  placeholder="Country"
                  value={manualData.countryName}
                  onChange={(e) =>
                    setManualData({
                      ...manualData,
                      countryName: e.target.value,
                    })
                  }
                />
                {validationErrors.countryName && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.countryName}
                  </small>
                )}
              </Col>

              <Col md={4} className="popup-col">
                <Form.Label>Address*</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={manualData.address}
                  onChange={(e) =>
                    setManualData({
                      ...manualData,
                      address: e.target.value,
                    })
                  }
                />
                {validationErrors.address && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.address}
                  </small>
                )}
              </Col>

              <Col md={4} className="popup-col">
                <Form.Label>Quantity*</Form.Label>
                <Form.Control
                  type="text"
                  name="Quantity"
                  placeholder="Quantity"
                  value={manualData.quantityNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const isValidQuantityNumber = /^[0-9()+\- ]*$/.test(
                      inputValue
                    );

                    if (isValidQuantityNumber) {
                      setManualData({
                        ...manualData,
                        quantityNumber: inputValue,
                      });
                    }
                  }}
                />
                {validationErrors.quantityNumber && (
                  <small className="text-danger" style={{ color: "red" }}>
                    {validationErrors.quantityNumber}
                  </small>
                )}
              </Col>

              <Col md={12} className="popup-col">
                {shouldShowProductId && (
                  <div>
                    <Form.Label>productId*</Form.Label>
                    <Form.Control
                      type="text"
                      name="productId"
                      value={popupData.id}
                      onChange={(e) =>
                        setPopupData({
                          ...popupData,
                          popupData: e.target.value,
                        })
                      }
                      disabled
                    />
                  </div>
                )}
              </Col>

              <h6 className="popup-h6">Company Details(Option)</h6>
              <Col md={12} className="popup-col">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  name="company name"
                  placeholder="Enter Company Name"
                />
              </Col>

              <Col md={12} className="popup-col">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="text"
                  name="contactnumber"
                  placeholder="Enter Contact Number"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9]/g, "");
                    e.target.value = numericValue;
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="w-100 mb-4n btn-singleenquiry"
            onClick={(e) => {
              e.preventDefault();
              handleGetEnquireSingle();
              if (validateForm()) {
                handleClosePopup();
              } else {
                console.log(
                  "Validation failed. Please check the form for errors."
                );
              }
            }}
            style={{ backgroundColor: "#1A68B2" }}
          >
            Enquire Now
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Popup;
