import { axiosInstance } from "../Api";

export const getEnquireDoubleApi = async (data) => {
  console.log("user::::", data);
  const params = new URLSearchParams();
  params.set("first_name", data.firstName);
  params.set("last_name", data.lastName);
  params.set("address1", data.address);
  params.set("country", data.countryName);
  params.set("phone", data.phoneNumber);
  params.set("email", data.emailId);

  console.log("params;;;;", params.toString());
  try {
    const updategetEnquireDouble = await axiosInstance.post(
      `/cart/enquiry/bulk?${params?.toString()}`
    );
    console.log("updategetEnquireDouble", updategetEnquireDouble);
    return updategetEnquireDouble.data;
  } catch (err) {
    console.log("error", err);
  }
};
