import React, { useEffect, useState } from "react";

import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { getClientApi } from "../../../Api/clientapi/ClientApi";

import "./Client.css";
import Loading from "../../loader/Loading";

export const Client = () => {
  const image1 = "helooooo";
  console.log("image1", image1);
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(true);

  const clientSlider = {
    loop: true,
    margin: 30,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 6,
      },
    },
  };

  useEffect(() => {
    const ClientApi = async () => {
      try {
        const updateClient = await getClientApi();
        
        setClient(updateClient.data);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
        setLoading(false);
      }
    };
    ClientApi();
  }, []);

  return (
    <>
      <section className="section-padding">
        <Container>
          {loading || client?.length === 0 ? (
            <div className="loader-client">
              <Loading />
            </div>
          ) : (
            <OwlCarousel
              className="owl-carousel owl-theme client-carousel"
              {...clientSlider}
            >
              {Array.isArray(client) &&
                client.map((items, index) => (
                  <div className="item" key={index}>
                    <div className="img-wrap">
                      <img
                        src={!items.photo ? "/clientgap.png" : items.photo}
                        alt={items.photo ? "Placeholder Image" : "Client Image"}
                        onError={(e) => {
                          e.target.src = "/clientgap.png"; // Path to your custom image
                          e.target.alt = "Placeholder Image"; // Alt text for the custom image
                        }}
                      />
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          )}
        </Container>
      </section>
    </>
  );
};
