import React, { useState } from "react";

import parse from "html-react-parser";
import { CgQuote } from "react-icons/cg";
import { Form, Modal } from "react-bootstrap";
import { RxCrossCircled } from "react-icons/rx";

const PopupTestimonial = ({ data, onClick }) => {
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
    // window.location.reload();
    onClick();
  };

  return (
    <div className="popup-container">
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        centered
        dialogClassName="modal-lg"
        className="testimonial-popup"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <CgQuote className="icon-testimonial" />
          </Modal.Title>
          <RxCrossCircled className="icon-popup" onClick={handleClosePopup} />
        </Modal.Header>

        <Modal.Body className="scrollable-modal-body">
          <Form className="popup-row">
            {data && <p>{parse(`${data.content || ""}`)}</p>}
            <div className="popup-content">
              <div className="popup-image">
                <img src={data.photo} alt="" />
              </div>
              <div className="popup-name">
                <p>{data.name}</p>
                <span>{data.designation}</span>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button
            variant="primary"
            className="w-100 mb-4"
            onClick={() => {
              handleClosePopup();
              handleGetEnquireSingle();
            }}
          >
            Enquire Now
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PopupTestimonial;
