import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { Col, Container, Row } from "react-bootstrap";

import "./ShopSection.css";
import parse from "html-react-parser";
import { getCard2Api, getCard3Api } from "../../../Api/about/About";

export const ShopSection = () => {
  const isSmallScreen = window.innerWidth <= 767;
  const [card2, setCard2] = useState("");
  const [card3, setCard3] = useState("");

  useEffect(() => {
    const updateCard2 = async () => {
      try {
        const updateCared2Data = await getCard2Api();
        setCard2(updateCared2Data.data);
      } catch (err) {
        console.log("error", err);
      }

      try {
        const updateCared3Data = await getCard3Api();
        setCard3(updateCared3Data.data);
      } catch (err) {
        console.log("error", err);
      }
    };
    updateCard2();
  }, []);

  return (
    <>
      <section className=" shop-section section-padding">
        <Container>
          <Row>
            <Col md={5}>
              <div className="shop-wrap right-wrap">
                <div className="img-wrap image-wrapped">
                  <img src={card2[0]?.blocks[0]?.image} alt="image1" />
                </div>
                <div className="content content-wrapped">
                  {card2[0]?.blocks?.map((item, index) => (
                    <React.Fragment key={index}>
                      <h2 className="title">{item?.title}</h2>
                      <p>{parse(item.content)}</p>
                    </React.Fragment>
                  ))}

                  <Link to="/allproduct" className="morebtn shop-now-link ">
                    Shop Now <IoIosArrowForward className="icon-shop-left" />
                  </Link>
                </div>
              </div>
            </Col>

            {/* <Col md={7} className="d-flex flex-column-reverse">
              <div className="shop-wrap ">
                <>
                  <div className="img-wrap shop-image mt-md-4 mt-2 ">
                    <img src="images/group1.png" alt="image1" />
                  </div>

                  <div className="content content-title">
                    <h2 className="title">Authentic Nepali Liquors</h2>
                    <p>
                      Best priced Nepali Liquors in Australia, free Shipping on
                      qualifying orders.
                    </p>
                    <Link to="/allproduct" className="morebtn">
                      Shop Now
                      <IoIosArrowForward className="icon-shop" />
                    </Link>
                  </div>
                </>
              </div>
            </Col> */}

            <Col md={7} className="d-md-flex flex-column-reverse">
              <div className="shop-wrap shop-section-wrap ">
                <>
                  <div className="content content-title">
                    {card3[0]?.blocks?.map((items, index) => (
                      <React.Fragment key={index}>
                        <h2 className="title">{items.title}</h2>
                        <p>{parse(items.content)}</p>
                      </React.Fragment>
                    ))}

                    <Link
                      to="/allproduct"
                      className="morebtn shop-now-link-right"
                    >
                      Shop Now <IoIosArrowForward className="icon-shop-right" />
                    </Link>
                  </div>
                  <div className="img-wrap shop-image mt-md-4 mt-1 ">
                    <img src={card3[0]?.blocks[0]?.image} alt="image1" />
                  </div>
                </>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
