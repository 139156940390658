import React, { useContext, useState } from "react";

import { BiX } from "react-icons/bi";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { useMediaQuery } from "react-responsive";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";

import "./Header.css";
import { ApiContext } from "../Product/ContextApiCategory";

export const Header = ({ isToggled, toggleOpenNavbar }) => {
  const { setActiveCategory } = useContext(ApiContext);

  const location = useLocation();

  const isHomePage = location.pathname === "/";
  const isProduct =
    location.pathname === "/allproduct" ||
    location.pathname === "/product-detail" ||
    location.pathname === "/allbrands";
  const isContact = location.pathname === "/contact";
  const isAbout = location.pathname === "/about";

  const isLogged = !!localStorage.getItem("access_token");
  const isSmallScreen = useMediaQuery({ query: "(max-width: 991.5px)" });

  const handleClickProduct = () => {
    setActiveCategory("all");
  };
  return (
    <>
      <div className="cl-mainheader">
        <Navbar expand="lg" className={isToggled ? "cl-navigation" : ""}>
          <Container>
            {/* <Navbar.Toggle
              aria-controls="navbarScroll"
              onClick={toggleOpenNavbar}
              className="navbar"
            ></Navbar.Toggle> */}

            <Navbar.Collapse
              id="navbarScroll"
              className={
                !isToggled && isSmallScreen ? "d-none" : isToggled ? "show" : ""
              }
            >
              <Navbar.Toggle
                onClick={toggleOpenNavbar}
                style={{ border: "none" }}
              >
                <BiX style={{ color: "rgb(208, 206, 206)" }} />
              </Navbar.Toggle>

              <Nav className="me-auto  my-lg-0">
                <Nav className="ms-auto  my-lg-0 " navbarScroll>
                  <Link
                    to="/"
                    className="nav-link"
                    activeClassName="active"
                    style={{
                      color: isSmallScreen
                        ? isHomePage
                          ? "white"
                          : "rgb(208, 206, 206)"
                        : isHomePage
                        ? "#1A68B2"
                        : "inherit",
                    }}
                  >
                    Home
                  </Link>
                  <Link
                    to="/about"
                    className="nav-link"
                    activeClassName="active"
                    style={{
                      color: isSmallScreen
                        ? isAbout
                          ? "white"
                          : "rgb(208, 206, 206)"
                        : isAbout
                        ? "#1A68B2"
                        : "inherit",
                    }}
                  >
                    About
                  </Link>
                  <Link
                    to="/allproduct"
                    className="nav-link"
                    activeClassName="active"
                    style={{
                      color: isSmallScreen
                        ? isProduct
                          ? "white"
                          : "rgb(208, 206, 206)"
                        : isProduct
                        ? "#1A68B2"
                        : "inherit",
                    }}
                    onClick={() => handleClickProduct()}
                  >
                    Products
                  </Link>
                  <Link
                    to="/contact"
                    className="nav-link"
                    activeClassName="active"
                    style={{
                      color: isSmallScreen
                        ? isContact
                          ? "white"
                          : "rgb(208, 206, 206)"
                        : isContact
                        ? "#1A68B2"
                        : "inherit",
                    }}
                  >
                    Contact
                  </Link>
                </Nav>
              </Nav>
            </Navbar.Collapse>

            {!isLogged ? (
              <div className="nav-right">
                <ul className="login-wrap">
                  {/* <li>
                    <Link className="nav-link" to="/login">
                      Login
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link className="nav-link" to="/register">
                      Register
                    </Link>
                  </li> */}
                </ul>
              </div>
            ) : null}
          </Container>
        </Navbar>
      </div>
    </>
  );
};
