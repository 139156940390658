import React, { useContext, useEffect, useState } from "react";
import { getTokenFromStorage } from "../Api/Api";
import { Footer } from "../Components/Footer/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import { getProfile } from "../Api/AuthApi/ProfileApi";
import { GlobalContext } from "../Context/GlobalContext";

import { TopHeader } from "../Components/Header/TopHeader/TopHeader";

const ProtectedLayout = () => {
  const navigate = useNavigate();
  const accessToken = getTokenFromStorage();
  const [loading, setLoading] = useState(true);

  const { setProfile } = useContext(GlobalContext);

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    setLoading(true);
    const fetchProfileData = async () => {
      try {
        const data = await getProfile();
        console.log("data;;;;;;;", data);
        if (data) {
          // set value in context
          setProfile(data.data);
        }
        setLoading(false);
      } catch (err) {
        console.log("layout error", err);
        // go to login page
        navigate("/login");
        // setLoading(false);
      }
    };
    fetchProfileData();
  }, [navigate, setProfile]);

  return (
    <div>
      <TopHeader />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default ProtectedLayout;
