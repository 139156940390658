import { axiosInstance } from "../Api";

export const getTestimonialApi = async () => {
  try {
    const updatetestimonial = await axiosInstance("/testimonials");
    console.log("updatetestimonial", updatetestimonial);
    return updatetestimonial.data;
  } catch (err) {
    console.log("error", err);
  }
};
