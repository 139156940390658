
import { axiosInstanceWithoutToken, storeToken } from "../Api";
export const getLogin = async (data) => {
  try {
    const loginResponse = await axiosInstanceWithoutToken.post(
      "/login?",
      null,
      {
        params: { email: data?.email, password: data?.password },
      }
    );
    console.log("loginResponse::", loginResponse);

    const accessToken = loginResponse.data.access_token;
    console.log("accessToken hi:", accessToken);
    storeToken(accessToken);
    return loginResponse.data;
  } catch (err) {
    console.log("login api fetch failed:", err);
  }
};
