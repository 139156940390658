import { axiosInstance } from "../Api";

export const getProductByTitle = async () => {
  const params = new URLSearchParams();
  params.set("sort_By", "title");
  console.log("params", params.toString());
  try {
    const updateProductTitle = await axiosInstance.post(
      `/product/filter?${params.toString()}`
    );
    console.log("updateProductTitle", updateProductTitle);
    return updateProductTitle.data;
  } catch (err) {
    console.log("error", err);
  }
};

export const getProductByCategory = async () => {
  const params = new URLSearchParams();
  params.set("sort_by", "cat_id");
  console.log("params", params.toString());
  try {
    const updateProductCat = await axiosInstance.post(
      `/product/filter?${params.toString()}`
    );
    console.log(" updateProductCat", updateProductCat);
    return updateProductCat.data;
  } catch (err) {
    console.log("error", err);
  }
};

export const getProductByPrice = async () => {
  const params = new URLSearchParams();
  params.set("sort_by", "price");
  try {
    const updateProductPrice = await axiosInstance.post(
      `product/filter?${params.toString()}`
    );
    console.log("updateProductPrice", updateProductPrice);
    return updateProductPrice.data;
  } catch (err) {
    console.log("error", err);
  }
};

export const getProductByBrand = async () => {
  const params = new URLSearchParams();
  params.set("sort_by", "brand_id");
  try {
    const updatedProductByBrand = await axiosInstance.post(
      `/product/filter?${params.toString()}`
    );
    console.log("updatedProductByBrand", updatedProductByBrand);
    return updatedProductByBrand.data;
  } catch (err) {
    console.log("error", err);
  }
};
