import { axiosInstance } from "../Api";

export const getClientApi = async () => {
  try {
    const updateClientApi = await axiosInstance.get("/retailers");
    console.log("updateClientApi", updateClientApi);
    return updateClientApi.data;
  } catch (err) {
    console.log("error", err);
  }
};
