import { axiosInstance } from "../Api";

export const getDeleteEnquireProduct = async (id) => {
  try {
    const updateDeleteEnquire = await axiosInstance(
      `/enquiry/cart-delete?id=${id}`
    );
    console.log("updateDeleteEnquire", updateDeleteEnquire);
    return updateDeleteEnquire.data;
  } catch (err) {
    console.log("error", err);
  }
};
