import React, { useEffect, useState } from "react";

import parse from "html-react-parser";

import { CgQuote } from "react-icons/cg";

import { Container } from "react-bootstrap";

import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import PopupTestimonial from "./PopupTestimonial";
import { getTestimonialApi } from "../../../Api/testimonial/TestimonialApi";

import "./Testimonial.css";
import Slider from "react-slick";
import Loading from "../../loader/Loading";

export const Testimonial = () => {
  const [test, setTest] = useState("");
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    dots: false,
    infinite: true,
    arrows: true,
    speed: 600,
    slidesToShow: 3,
    centermargin: 10,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 545,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          backgroundColor: "white",
          height: "50px",
          width: "50px",
          borderRadius: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <IoIosArrowForward style={{ fontSize: "24px", color: "#5D5A88" }} />
      </div>
    );
  }

  function PreviousArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          backgroundColor: "white",
          height: "50px",
          width: "50px",
          borderRadius: "100%",
          justifyContent: "center",
          alignItems: "center",

          zIndex: "1",
        }}
        onClick={onClick}
      >
        <IoIosArrowBack
          style={{ fontSize: "24px", color: "#5D5A88", margin: "13px 5px" }}
        />
      </div>
    );
  }

  useEffect(() => {
    const getTestimonial = async () => {
      try {
        const updatetestimonialApi = await getTestimonialApi();
        setTest(updatetestimonialApi.data);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
        setLoading(false);
      }
    };
    getTestimonial();
  }, []);

  const handlePopup = (data) => {
    setSelectedTestimonial(data);
    setShowPopup(true);
  };

  return (
    <>
      <section className="section-padding">
        <Container>
          <div className="cl-header">
            <span className="subtitle">from the testimonials</span>
            <h2 className="maintitle">Happy Customers Say.</h2>
          </div>
          {loading || test?.length === 0 ? (
            <div className="loader-test">
              <Loading />
            </div>
          ) : (
            <Slider
              className="owl-carousel owl-theme testimonial-carousel"
              {...settings}
            >
              {Array.isArray(test) &&
                test.map((data, index) => (
                  <div className="item" key={index}>
                    <div className="t-wrap">
                      <CgQuote className="icon" />
                      <div className="t-content">
                        <div className="text-limit">
                          <p>
                            {console.log(data.content) ||
                              parse(`${data.content || ""}`.slice(0, 130))}
                          </p>
                        </div>
                        {data.content.length > 130 && (
                          <h6
                            className="popupspan"
                            onClick={(e) => {
                              e.preventDefault();
                              handlePopup(data);
                            }}
                            style={{
                              cursor: "pointer",
                              marginTop: "20px",
                              color: "gray",
                            }}
                          >
                            see more
                          </h6>
                        )}

                        <div className="t-info ">
                          <div className="img-wrap">
                            <img
                              src={data.photo}
                              alt=""
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>

                          <div className="name">
                            <strong>{data.name}</strong>
                            <span>{data.designation}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          )}
        </Container>
      </section>

      {showPopup && selectedTestimonial && (
        <PopupTestimonial
          data={selectedTestimonial}
          onClick={(e) => {
            e?.preventDefault();
            handlePopup();
          }}
        />
      )}
    </>
  );
};
