import React, { useEffect, useState } from "react";

import parse from "html-react-parser";

import { Col, Container, Row } from "react-bootstrap";
import { getReasonWhyApi } from "../../../Api/reasonWhy/WhyUsApi";

import "./Whyus.css";
import Loading from "../../loader/Loading";


export const Whyus = () => {
  const [loading, setLoading] = useState(true);
  const [reasonWhy, setReasonWhy] = useState("");

  useEffect(() => {
    const getApiReason = async () => {
      try {
        const updateReasonApi = await getReasonWhyApi();
    
        setReasonWhy(updateReasonApi.data[0]);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
        setLoading(false);
      }
    };
    getApiReason();
  }, []);

  return (
    <>
      <section
        className="whyreason-section-padding whyus-section "
        // style={{ marginTop: "96px" }}
      >
        <Container className="whyus-reason">
          <div className="cl-header">
            <span className="subtitle">Why CHOOSE US</span>
            <h2 className="maintitle">Reasons To Choose Us</h2>
          </div>
          {loading || reasonWhy?.length === 0 ? (
            <div className="loader-reasonwhy">
              <Loading />
            </div>
          ) : (
            <Row>
              {reasonWhy?.blocks?.map((data, index) => (
                <Col md={4} sm={6} className="p-0" key={index}>
                  <div className="whyus-wrap">
                    <div className="icon-wrap">
                      <img src={data.image} alt={`Image ${index + 1}`} />
                      <span className="sn">
                        {index < 9 ? `0${index + 1}` : index + 1}
                      </span>
                    </div>
                    <div className="content">
                      <h2 className="title">{data.title}</h2>
                      <p>
                        {console.log(data.content) ||
                          parse(`${data.content || ""}`)}
                      </p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};
