import { axiosInstance } from "../Api";

export const getEnquiryHistory = async () => {
  try {
    const updateEnquiryHistory = await axiosInstance.get("/enquiry-history");
    console.log("updateEnquiryHistory", updateEnquiryHistory);
    return updateEnquiryHistory.data;
  } catch (err) {
    console.log("error", err);
  }
};
