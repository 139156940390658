import React, { useEffect, useState } from "react";
// import "./Product.css";
import { MoonLoader } from "react-spinners";
import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import smoothscroll from "smoothscroll-polyfill";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

import { getProductApi } from "../../Api/productApi/ProductApi";
import { getProductDetailApi } from "../../Api/productApi/ProductDetail";
import Loading from "../loader/Loading";

export const Product = () => {
  const navigate = useNavigate();

  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemDetail, setItemDetail] = useState([]);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const updateProducts = await getProductApi();
        console.log("updateProducts", updateProducts);
        setProduct(updateProducts.data);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
      }
    };

    getProduct();
  }, []);

  const handleClick = async (slug) => {
    console.log("Clicked on product with id:", slug);

    try {
      const updateDetails = await getProductDetailApi(slug);
      console.log("updatDetails", updateDetails);
      const adjustedIndex = slug - 1;

      navigate(`/product-detail?slug=${slug}`, {
        state: { productData: updateDetails, slug: adjustedIndex },
      });
      setItemDetail(itemDetail);
      console.log("setItemDetail", itemDetail);
    } catch (err) {
      console.log(err);
    }
  };

  const productslider = {
    loop: true,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  smoothscroll?.polyfill();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth", block: "start" });
  };

  return (
    <>
      <section className="section-padding">
        <Container>
          <div className="cl-header">
            <h2 className="title">Popular Products</h2>
          </div>
          {loading || product?.length === 0 ? (
            <div className="loader-product">
              <Loading />
            </div>
          ) : (
            <OwlCarousel
              className="owl-theme owl-carousel product-carousel"
              {...productslider}
            >
              {Array.isArray(product) &&
                product.map((data, index) => (
                  <div className="item" key={index}>
                    <div
                      className="product-wrap"
                      onClick={() => handleClick(data.slug)}
                    >
                      <Link to="">
                        <div className="img-product">
                          {/* <span className="tag">New</span> */}
                          <img src={data.photo} alt="brand1" />
                        </div>
                        <h6 className="title">{data.title}</h6>
                        <span className="link-popup">Enquire</span>
                      </Link>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          )}
          <div className="morebtn-wrap text-right mt-10">
            <Link to="/allproduct" className="morebtn" onClick={scrollToTop}>
              All Products <IoIosArrowForward className="icon-product" />
            </Link>
          </div>
        </Container>
      </section>
    </>
  );
};
