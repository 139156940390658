import axios from "axios";

//token sahit ko lagi yo
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

//token bina ko lagi yo
export const axiosInstanceWithoutToken = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const storeToken = (newToken) => {
  console.log("Token stored in localStorage:", newToken);
  localStorage.setItem("access_token", newToken);
};

const getTokenFromStorage = () => {
  const access_token = localStorage.getItem("access_token");
  console.log("Token retrieved from localStorage:", access_token);
  return access_token;
};

export const removeTokenFormStorage = () => {
  localStorage.removeItem("access_token");
  console.log("Token remove from local storage:");
};


axiosInstance.interceptors.request.use(
  (config) => {
    const api_token = getTokenFromStorage();
    console.log("Token in interceptor:", api_token);
    if (api_token) {
      config.headers["Authorization"] = `Bearer ${api_token}`;
    }
    console.log("config.headers", config.headers);
    return config;
  },
  (error) => Promise.reject(error)
);

export { storeToken, getTokenFromStorage };

