import { axiosInstance } from "../Api";

export const getEnquireSingleApi = async (userData) => {
  console.log("data;;;;", userData);
  const params = new URLSearchParams();
  params.set("first_name", userData.firstName);
  params.set("last_name", userData.lastName);
  params.set("address1", userData.address);
  params.set("country", userData.countryName);
  params.set("phone", userData.phoneNumber);
  params.set("email", userData.emailId);
  params.set("product_id", userData.id);
  params.set("quantity", userData.quantityNumber);
  console.log("params", params.toString());
  try {
    const updateEnquireSingle = await axiosInstance.post(
      `/cart/enquiry/single?${params?.toString()}`
    );
    console.log("updateEnquireSingle;;;", updateEnquireSingle);
    return updateEnquireSingle.data;
  } catch (err) {
    console.log("error", err);
  }
};
