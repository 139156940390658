import { axiosInstance } from "../Api";

export const getProductViewNine = async () => {
  const params = new URLSearchParams();
  params.set("per_page", "9");
  try {
    const updateProductNine = await axiosInstance.post(
      `/product/filter?${params.toString()}`
    );
    console.log("updateProductNine", updateProductNine);
    return updateProductNine.data;
  } catch (err) {
    console.log("error", err);
  }
};

export const getProductViewfifteen = async () => {
  const params = new URLSearchParams();
  params.set("per_page", "15");

  try {
    const updatedViewfifteen = await axiosInstance.post(
      `/product/filter?${params.toString()}`
    );
    console.log("updatedViewfifteen", updatedViewfifteen);
    return updatedViewfifteen.data;
  } catch (err) {
    console.log("error", err);
  }
};

export const getProductViewTwenty = async () => {
  const params = new URLSearchParams();
  params.set("per_page", "20");

  try {
    const updatedViewtwenty = await axiosInstance.post(
      `/product/filter?${params.toString()}`
    );
    console.log("updatedViewtwenty", updatedViewtwenty);
    return updatedViewtwenty.data;
  } catch (err) {
    console.log("error", err);
  }
};
