import { axiosInstance } from "../Api";

export const getAboutApi = async () => {
  const params = new URLSearchParams();
  params.set("pagename", "About US");
  console.log("params", params.toString());
  try {
    const updateAboutApi = await axiosInstance.post(
      `/cms?${params?.toString()}`
    );

    console.log("updateAboutApi", updateAboutApi);
    return updateAboutApi.data;
  } catch (err) {
    console.log("error", err);
  }
};


export const getCardApi = async () => {
  const params = new URLSearchParams();
  params.set("pagename", "Card 1");
  console.log("params", params.toString());
  try {
    const updateAboutApi = await axiosInstance.post(
      `/cms?${params?.toString()}`
    );

    console.log("updateAboutApi", updateAboutApi);
    return updateAboutApi.data;
  } catch (err) {
    console.log("error", err);
  }
};


export const getCard2Api = async () => {
  const params = new URLSearchParams();
  params.set("pagename", "Card 2");
  console.log("params", params.toString());
  try {
    const updateAboutApi = await axiosInstance.post(
      `/cms?${params?.toString()}`
    );

    console.log("updateAboutApi", updateAboutApi);
    return updateAboutApi.data;
  } catch (err) {
    console.log("error", err);
  }
};


export const getCard3Api = async () => {
  const params = new URLSearchParams();
  params.set("pagename", "Card 3");
  console.log("params", params.toString());
  try {
    const updateAboutApi = await axiosInstance.post(
      `/cms?${params?.toString()}`
    );

    console.log("updateAboutApi", updateAboutApi);
    return updateAboutApi.data;
  } catch (err) {
    console.log("error", err);
  }
};


export const getAboutBrandApi = async () => {
  const params = new URLSearchParams();
  params.set("pagename", "about brands");
  console.log("params", params.toString());
  try {
    const updateAboutApi = await axiosInstance.post(
      `/cms?${params?.toString()}`
    );

    console.log("updateAboutApi", updateAboutApi);
    return updateAboutApi.data;
  } catch (err) {
    console.log("error", err);
  }
};