import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import { MoonLoader } from "react-spinners";
import { AiOutlineHome } from "react-icons/ai";
import { LiaPhoneSolid } from "react-icons/lia";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";

import { getContactApi } from "../../../Api/contactApi/Contact";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { getContactFormApi } from "../../../Api/contactApi/ContectFormApi";

import "./Contact.css";
import Loading from "../../loader/Loading";

export const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState();
  const [contactForm, setContactForm] = useState("");
  const [contactData, setContactData] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();

  useEffect(() => {
    const ContactApi = async () => {
      try {
        const updateContectApi = await getContactApi();
        setContactData(updateContectApi.data[0]);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
        setLoading(false);
      }
    };
    ContactApi();
  }, []);

  const handleContactFormApi = async (data) => {
    if (isFormValid) {
      try {
        const formData = {
          name: data?.Name || "",
          email: data?.Email || "",
          message: data?.Message || "",
        };

        const combineData = {
          ...formData,
          ...contactForm,
        };

        const updateContactApi = await getContactFormApi(combineData);
        console.log("updateContactApi...", updateContactApi);
        toast.success("Message sent successfully");
        setContactForm({
          subject: "",
        });
        reset();
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid]);

  return (
    <>
      <div>
        {loading || contactData?.length === 0 ? (
          <div className="loader-contactData">
            <Loading />
          </div>
        ) : (
          <div>
            {contactData && (
              <section className="">
                <div
                  className="location-map"
                  dangerouslySetInnerHTML={{
                    __html: contactData.google_map,
                  }}
                />

                <div className="contact-section">
                  <Container>
                    <Row>
                      <Col md={6} lg={5}>
                        <div className="contact-info contact-list-info">
                          <div className="cl-header p-header">
                            <span className="subtitle contact">
                              Contact information
                            </span>
                            <h2 className="maintitle">Get In Touch</h2>
                          </div>
                          <p>
                            This typeface has a lot of outstanding features if
                            you want to use this awesome font in your plans and
                            functions.
                          </p>
                          <ul className="contact-list ">
                            <>
                              <li>
                                <div className="contact-icons">
                                  <LiaPhoneSolid className="icon-contact" />
                                  <span> Hotline 24/7:</span>
                                </div>
                                <div>
                                  <Link
                                    to="tel:+61 7492-4277"
                                    className="phone"
                                  >
                                    {contactData?.phone}
                                  </Link>
                                </div>
                              </li>
                              <li className="contact-icons">
                                <AiOutlineHome className="icon-contact" />
                                <span>{contactData?.address}</span>
                              </li>
                              <li className="contact-icons">
                                <HiOutlineEnvelope className="icon-contact" />
                                <Link to={`mailto:${contactData?.email}`}>
                                  {contactData?.email}
                                </Link>
                              </li>
                            </>
                          </ul>
                        </div>
                      </Col>

                      <Col md={6} lg={{ span: 6 }} className="bl">
                        <div className="contact-form">
                          <Form onSubmit={handleSubmit(handleContactFormApi)}>
                            <h1
                              style={{ fontSize: "36px", marginBottom: "32px" }}
                            >
                              Let’s Talk
                            </h1>
                            <Form.Group
                              controlId="validationCustom01"
                              className="mb-4"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Subject"
                                value={contactForm.subject}
                                onChange={(e) =>
                                  setContactForm({
                                    ...contactForm,
                                    subject: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              controlId="validationCustom01"
                              className="mb-4"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Your Name*"
                                defaultValue=""
                                {...register("Name", {
                                  required: "The name field is required.",
                                  pattern: {
                                    value: /^[A-Za-z]+$/i,
                                    message: "The name field is required.",
                                  },
                                })}
                              />
                              {errors.Name && (
                                <p
                                  className="error-message"
                                  style={{ color: "red" }}
                                >
                                  {errors.Name.message}
                                </p>
                              )}
                            </Form.Group>
                            <Form.Group
                              controlId="validationCustom01"
                              className="mb-4"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Your Email*"
                                {...register("Email", {
                                  required: "The email field is required.",
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Invalid email address .",
                                  },
                                })}
                              />
                              {errors.Email && (
                                <p
                                  className="error-message"
                                  style={{ color: "red" }}
                                >
                                  {errors.Email.message}
                                </p>
                              )}
                            </Form.Group>
                            <Form.Group
                              controlId="validationCustom01"
                              className="mb-4"
                            >
                              <Form.Control
                                as="textarea"
                                placeholder="Write your message here*"
                                rows={3}
                                {...register("Message", {
                                  required: "The message field is required.",
                                  pattern: {
                                    message: "The message field is required.",
                                  },
                                })}
                              />
                              {errors.Message && (
                                <p
                                  className="error-message"
                                  style={{ color: "red" }}
                                >
                                  {errors.Message.message}
                                </p>
                              )}
                            </Form.Group>
                            <Button
                              className="cl-button w-100"
                              type="submit"
                              style={{ marginTop: "32px" }}
                            >
                              Send Message
                            </Button>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </section>
            )}
          </div>
        )}
      </div>
    </>
  );
};
