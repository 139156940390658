import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { MoonLoader } from "react-spinners";
import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";

import { IoIosArrowForward } from "react-icons/io";
import { getBrandApi } from "../../Api/brandApi/BrandApi";

import "./Brand.css";
import { useBrandContext } from "./BrandContextApi";
import Loading from "../loader/Loading";

export const Brand = () => {
  const [brand, setBrand] = useState("");
  const [loading, setLoading] = useState(true);
  const { getUpdateBrandId } = useBrandContext();

  const brandslider = {
    loop: true,
    margin: 30,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    responsive: {
      0: {
        items: 2,
        nav: true,
      },
      600: {
        items: 3,
        nav: true,
      },
      1000: {
        items: 7,
        nav: true,
      },
    },
  };

  useEffect(() => {
    const BrandApi = async () => {
      try {
        const updateBrandApi = await getBrandApi();
        console.log("updateBannerApi", updateBrandApi);
        setBrand(updateBrandApi.data);
        setLoading(false);
      } catch (err) {
        console.log("error", err);
        setLoading(false);
      }
    };
    BrandApi();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClick = (id) => {
    getUpdateBrandId(id);
  };

  return (
    <>
      <section className="section-padding">
        <Container>
          <div className="cl-header">
            <h2 className="title">Shop by Brands</h2>
          </div>
          {loading || brand.length === 0 ? (
            <div className="loader-products">
              <Loading />
            </div>
          ) : (
            <OwlCarousel
              className="owl-theme owl-carousel top-brand-header brand-carousel"
              {...brandslider}
            >
              {Array.isArray(brand) &&
                brand.map((items, index) => (
                  <div className="item" key={index}>
                    <div
                      className="brand-wrap"
                      onClick={() => handleClick(items.id)}
                    >
                      <div className="img-wrap">
                        <img src={items.photo} alt="brand1" />
                      </div>
                      <h6 className="title">{items.title}</h6>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          )}

          <div className="morebtn-wrap text-right">
            <Link to="/allbrands" className="brand-link" onClick={scrollToTop}>
              All Brands <IoIosArrowForward className="icon-barnd" />
            </Link>
          </div>
        </Container>
      </section>
    </>
  );
};
