import { axiosInstance, getTokenFromStorage } from "../Api";

export const getListEnquire = async (slug) => {
  try {
    const api_token = getTokenFromStorage();
    console.log("tennnnn", api_token);
    const updateSingleEnquire = await axiosInstance.get(
      `/enquiry/add-to-cart/single?slug=${slug}`
    );

    console.log("updateSingleEnquire", updateSingleEnquire);
    return updateSingleEnquire.data;
  } catch (err) {
    console.log("error", err);
  }
};
