import { axiosInstance } from "../Api";

export const getReasonWhyApi = async () => {
  const params = new URLSearchParams();
  params.set("pagename", "Reasons To Choose Us");
  console.log("paramss", params.toString());
  try {
    const updateReasonwhyUsApi = await axiosInstance.post(
      `/cms?${params?.toString()}`
    );
    console.log("updateReasonwhyUsApi", updateReasonwhyUsApi);
    return updateReasonwhyUsApi.data;
  } catch (err) {
    console.log("error", err);
  }
};
