import React, { useEffect, useRef, useState } from "react";

import Slider from "react-slick";

import parse from "html-react-parser";
import { toast } from "react-toastify";
import Magnifier from "react-magnifier";
import "slick-carousel/slick/slick.css";

import { useBadge } from "../BadageContext";
import "slick-carousel/slick/slick-theme.css";

import Popup from "../../innerPage/EnquiryList/Popup";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getListEnquire } from "../../../Api/enquireApi/EnquireApi";
import { getAddToCartEnquire } from "../../../Api/enquireApi/AddToListEnquire";

export const ProductItemDetail = () => {
  let addToCartTimeout;
  const { setProductListData } = useBadge();

  const navigate = useNavigate();
  const location = useLocation();

  const productData = location.state && location.state.productData;
  const productItemDetailIndex = location.state && location?.state?.slug;

  const { incrementBadge } = useBadge();
  const [itemDetailIndex, setItemDetailIndex] = useState(0);

  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [popupData, setPopupData] = useState({ title: "", id: "" });

  const isLogged = !!localStorage.getItem("access_token");

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
    setItemDetailIndex(productItemDetailIndex);
    if (productData?.data?.photos) {
      setSelectedImage(productData?.data?.photos[0] || {});
    }
    setPopupData({
      title: productData?.data?.title || "",
      id: productData?.data?.id || "",
    });
  }, [productData, productItemDetailIndex, itemDetailIndex]);

  const handleClickPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClickSingleEnquire = async (slug) => {
    clearTimeout(addToCartTimeout);
    addToCartTimeout = setTimeout(async () => {
      try {
        if (isLogged) {
          const updateSingleEnquire = await getListEnquire(slug);
          console.log("updateSingleEnquire", updateSingleEnquire);
        } else {
          navigate("/login");
        }
      } catch (err) {
        console.log("error", err);
      }
    }, 500);
  };

  const handleClickAddToCart = async (slug) => {
    clearTimeout(addToCartTimeout);
    addToCartTimeout = setTimeout(async () => {
      try {
        const updateProductToCart = await getAddToCartEnquire(slug);
        console.log("updateProductToCart", updateProductToCart);
        setProductListData(updateProductToCart.data);
        incrementBadge();
        toast.success("Successfully added to enquiry cart");
      } catch (err) {
        console.log("error", err);
      }
    }, 500);
  };

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <>
      <section className="section-padding productdetail-section">
        <Container>
          <Row className="b-bottom">
            <Col md={6}>
              <div className="product-zoom-wrap d-flex ">
                <div className="w-full item">
                  <Magnifier src={selectedImage} />
                </div>

                <Slider
                  className="thumbnail-slider"
                  ref={(slider) => (slider2.current = slider)}
                  slidesToShow={4}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  vertical={true}
                  verticalSwiping={true}
                  arrows={false}
                >
                  {Array.isArray(productData?.data?.photos) ? (
                    productData?.data?.photos?.map((image, index) => (
                      <div
                        className="item"
                        key={index}
                        onClick={() => handleThumbnailClick(image)}
                      >
                        <img src={image} alt={`Thumbnail${image + 1}`} />
                      </div>
                    ))
                  ) : (
                    <p>Loading..</p>
                  )}
                </Slider>
              </div>
            </Col>
            {productData && (
              <Col md={6}>
                <div className="product-zoom-content">
                  <h2 className="title">
                    {console.log("productData:", productData)}

                    {productData?.data?.title}
                  </h2>
                  <p className="status">
                    Availability: <span>{productData?.data?.stock}</span>
                  </p>
                  <div className="butn-wrap">
                    <button
                      className="cl-button"
                      onClick={() => {
                        handleClickPopup();
                        handleClickSingleEnquire(productItemDetailIndex);
                      }}
                    >
                      Enquire Now
                    </button>
                    <button
                      className="cl-button-dark"
                      onClick={() => {
                        if (isLogged) {
                          if (productData?.data?.stock > 0) {
                            handleClickAddToCart(productItemDetailIndex);
                          } else {
                            toast.warning("Product is out of stock.");
                          }
                        } else {
                          navigate("/login");
                        }
                      }}
                    >
                      Add to Enquire List
                    </button>
                  </div>
                  <ul>
                    <li className="status">
                      Brand: <span>{productData?.data?.brand_name} </span>
                    </li>
                    <li className="status">
                      Categories:
                      <span> {productData?.data?.category_name}</span>
                    </li>
                  </ul>
                </div>
              </Col>
            )}
          </Row>
          {productData && (
            <Row className="justify-content-center">
              <Col md={8}>
                <div className="desc-wrap ">
                  <div className="cl-header text-center">
                    <h2 className="cl-title">
                      Description
                      {/* {parse(productData.data?.title)} */}
                    </h2>
                  </div>
                  {parse(productData.data?.description)}
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </section>
      {showPopup && (
        <Popup
          onClick={handleClosePopup}
          title={popupData.title}
          // id={popupData.id}
          popupData={popupData}
          setPopupData={setPopupData}
        />
      )}
    </>
  );
};
