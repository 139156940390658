import { axiosInstance } from "../Api";

export const getBannerApi = async () => {
  try {
    const bannerApi = await axiosInstance.get("/banners");
    console.log("bannerApi", bannerApi);
    return bannerApi.data;
  } catch (err) {
    console.log("error", err);
  }
};
